










import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import Velocity from "velocity-animate";

@Component
export default class SlideDownTransition extends Vue {
  @Prop({ default: 300, validator: (value: number) => value >= 0 })
  duration!: number;
  @Prop({ default: 0, validator: (value: number) => value >= 0 })
  enterDelay!: number;

  beforeEnter(el: HTMLElement): void {
    el.style.transformOrigin = "left top";
    el.style.transform = "scaleY(0)";
    el.style.opacity = "0";
  }

  enter(
    el: HTMLElement,
    done: () => void,
    duration: number,
    delay: number
  ): void {
    Velocity(
      el,
      {
        transform: ["scaleY(1)", "scaleY(0)"],
        opacity: 1
      },
      {
        duration,
        delay,
        complete: done
      }
    );
  }

  leave(el: HTMLElement, done: () => void, duration: number): void {
    Velocity(
      el,
      {
        transform: ["scaleY(0)", "scaleY(1)"],
        opacity: 0
      },
      {
        duration,
        complete: done
      }
    );
  }

  afterLeave(el: HTMLElement): void {
    el.style.transform = "none";
    el.style.opacity = "1";
  }
}
